<template>
  <div>
    <a-row type="flex" style="margin:5px 15px;">

      <!-- Header Breadcrumbs & Title Column -->
      <a-col :span="24" :md="24">

        <div class="ant-page-header-heading" v-if="selectedItem">
          <span class="ant-page-header-heading-title text-primary">{{ selectedItem.title }} <span
              style="color:rgba(0, 0, 0, 0.45);font-weight: 600;">#{{ selectedItem.postNumber }}</span>
           <a-tag size="small"
                  :class="{'ant-tag-success':selectedItem.status=='published'||selectedItem.status=='Published','ant-tag-primary':selectedItem.status=='draft'||selectedItem.status=='Draft','ant-tag-danger':selectedItem.status=='deleted'||selectedItem.status=='Deleted','ant-tag-warning':selectedItem.status=='Inactive'||selectedItem.status=='inactive'}"
                  style="margin-left: 10px">
            {{ selectedItem ? selectedItem.status : '' | capitalizeFirstLetter }}
          </a-tag>
          </span>
        </div>
        <div v-else>
          <a-skeleton active title/>
        </div>
      </a-col>
    </a-row>
    <a-tabs v-model="activeKey" @change="callback">
      <a-tab-pane key="1" tab="Basic Information">
        <router-view v-if="activeKey=='1'" @changeTab="changeTab"></router-view>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
        Images & Sources
        <a-tooltip placement="top">
                    <template slot="title">
                      <span>No images added</span>
                    </template>
<a-icon type="warning" class="blink-icon" v-if="selectedItem&&!selectedItem.imagesDetail.length" theme="twoTone"
        two-tone-color="orange"/>
                  </a-tooltip>
      </span>
        <router-view v-if="activeKey=='2'" @changeTab="changeTab"></router-view>
      </a-tab-pane>
      <a-tab-pane key="3" :tab="'Publish/Summary'">
        <router-view v-if="activeKey=='3'" @changeTab="changeTab"></router-view>
      </a-tab-pane>
      <a-tab-pane key="4" tab="Comments">
        <router-view v-if="activeKey=='4'" @changeTab="changeTab"></router-view>
      </a-tab-pane>
      <a-tab-pane key="5" tab="Reports">
        <router-view v-if="activeKey=='5'" @changeTab="changeTab"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeKey: this.$route.name == 'Edit Album | Basic Info' ? '1' : (this.$route.name == 'Edit Album | Images' ? '2' : (this.$route.name == 'Edit Album | Summary' ? '3' : (this.$route.name == 'Edit Album | Comments' ? '4' : (this.$route.name == 'Edit Album | Reports' ? '5' : '3')))),
      prevKey: this.$route.name == 'Edit Album | Basic Info' ? '1' : (this.$route.name == 'Edit Album | Images' ? '2' : (this.$route.name == 'Edit Album | Summary' ? '3' : (this.$route.name == 'Edit Album | Comments' ? '4' : (this.$route.name == 'Edit Album | Reports' ? '5' : '3')))),
    };
  },
  methods: {
    callback(key) {
      if (this.prevKey != '1' && this.prevKey != '2') {
        this.prevKey = this.activeKey;
        if (this.activeKey == '1')
          this.$router.push('basic-info').catch((err) => {
          })
        else if (this.activeKey == '2')
          this.$router.push('images').catch((err) => {
          })
        else if (this.activeKey == '3')
          this.$router.push({name: 'Edit Album | Summary'}).catch((err) => {
          })
        else if (this.activeKey == '4')
          this.$router.push({name: 'Edit Album | Comments'}).catch((err) => {
          })
        else if (this.activeKey == '5')
          this.$router.push({name: 'Edit Album | Reports'}).catch((err) => {
          })
      } else {
        if (confirm('Some changes might not have been saved. Are you sure want to proceed?') == true) {
          this.prevKey = this.activeKey;
          if (this.activeKey == '1')
            this.$router.push('basic-info').catch((err) => {
            })
          else if (this.activeKey == '2')
            this.$router.push('images').catch((err) => {
            })
          else if (this.activeKey == '3')
            this.$router.push({name: 'Edit Album | Summary'}).catch((err) => {
            })
          else if (this.activeKey == '4')
            this.$router.push({name: 'Edit Album | Comments'}).catch((err) => {
            })
          else if (this.activeKey == '5')
            this.$router.push({name: 'Edit Album | Reports'}).catch((err) => {
            })

        } else {
          this.activeKey = this.prevKey;
        }
      }


    },
    changeTab(key) {
      this.prevKey = key;
      this.activeKey = key

      if (this.activeKey == '1')
        this.$router.push('basic-info').catch((err) => {
        })
      else if (this.activeKey == '2')
        this.$router.push('images').catch((err) => {
        })
      else if (this.activeKey == '3')
        this.$router.push('summary').catch((err) => {
        })
      else if (this.activeKey == '4')
        this.$router.push({name: 'Edit Album | Comments'}).catch((err) => {
        })
      else if (this.activeKey == '5')
        this.$router.push({name: 'Edit Album | Reports'}).catch((err) => {
        })
    },
    handleMenuClick(key) {
      this.$confirm({
        title: 'Are you sure update the item status?',
        content: 'Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          let status = this.selectedItem.status
          if (key.key == '11')
            status = 'deleted'
          else if (key.key == '22')
            status = 'inactive'
          else if (key.key == '33')
            status = 'draft'
          else if (key.key == '44')
            status = 'published'

          this.$store.dispatch("UPDATE_POST_STATUS", {status: status, id: this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                // this.confirmLoading = false
              });
        },
      });

    }

  },
  computed: {
    selectedItem() {
      return this.$store.getters.selectedPost
    }
  },
};
</script>